import { extend, localize } from 'vee-validate'
import {
  required as rule_required,
  required_if as rule_required_if,
  email as rule_email,
  min as rule_min,
  min_value as rule_min_value,
  confirmed as rule_confirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as rule_integer,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  length as rule_length,
} from 'vee-validate/dist/rules'
import nl from './locale/nl.json'

// eslint-disable-next-line object-curly-newline
import { validatorPositive, validatorUrlValidator, validatorPassword, validatorCreditCard } from './validators'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////
export const decimal = extend('decimal', {
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false,
      }
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      }
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`)

    return {
      valid: regex.test(value),
    }
  },
  message: 'Dit veld mag alleen numerieke of decimale waarden bevatten',
})

export const date = extend('date', {
  validate: (value = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false,
      }
    }

    if (/^\d{4}-\d{2}-\d{2}$/.test(value)) {
      return {
        valid: true,
      }
    }

    if (/^\d{2}-\d{2}-\d{4}$/.test(value)) {
      return {
        valid: true,
      }
    }

    return {
      valid: false,
    }
  },
  message: 'Datum format niet correct',
})

export const postalcode = extend('postalcode', {
  validate: (value = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false,
      }
    }

    if (/^[1-9][0-9]{3}[\040|\x20]*[A-Za-z]{2}$/i.test(value)) {
      return {
        valid: true,
      }
    }

    return {
      valid: false,
    }
  },
  message: 'Postcode format niet correct (Correct voorbeeld: 3333AA)',
})

export const phoneNumber = extend('phoneNumber', {
  validate: (value = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false,
      }
    }

    if (/(^0[0-9]{9}$)|(^0031[0-9]{9}$)|(^[+]31[0-9]{9}$)/i.test(value)) {
      return {
        valid: true,
      }
    }

    return {
      valid: false,
    }
  },
  message: 'Telefoonnummer niet correct',
})

export const iban = extend('iban', {
  validate: (value = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false,
      }
    }

    if (/^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/i.test(value)) {
      return {
        valid: true,
      }
    }

    return {
      valid: false,
    }
  },
  message: 'IBAN is niet correct',
})

export const test = extend('test', {
  validate: (value = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false,
      }
    }
    if (value >= 10) {
      return {
        valid: true,
      }
    }
    return {
      valid: false,
    }
  },
  message: 'IBAN is niet correct',
})

export const required = extend('required', rule_required)

export const requiredIf = extend('required_if', rule_required_if)

export const email = extend('email', rule_email)

export const min = extend('min', rule_min)

export const minValue = extend('min_value', rule_min_value)

export const confirmed = extend('confirmed', rule_confirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', rule_integer)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const length = extend('length', rule_length)

export const positive = extend('positive', {
  validate: validatorPositive,
  message: 'Please enter positive number!',
})

export const credit = extend('credit-card', {
  validate: validatorCreditCard,
  message: 'It is not valid credit card!',
})

export const password = extend('password', {
  validate: validatorPassword,
  message: 'Uw wachtwoord moet minimaal één hoofdletter, één kleine letter, één speciaal teken en één cijfer bevatten',
})

export const url = extend('url', {
  validate: validatorUrlValidator,
  message: 'URL is invalid',
})

// Install English and Arabic localizations.
localize({
  en: {
    messages: nl.messages,
    names: {
      email: 'Email',
      password: 'Password',
      name: 'Naam',
    },
    fields: {
      password: {
        min: '{_field_} is too short, you want to get hacked?',
      },
    },
  },
})
